<template>
  <div style="font-size: 0.14rem;color: #fff;">
    <div style="padding:0 0.26rem;margin-top: 0.2rem">
      <p style="margin-top: 0.1rem">
        <template v-for="(item, index) in statistics">
          <span style="white-space: revert">
            <span>{{ item.label }}</span>
            <span
              style="color: #00F9FF;font-weight: bold;margin-right:0.5rem">
              {{ item.count }}
            </span>
</span>
        </template>
      </p>
      <p style="margin-top: 0.1rem">
        <span>今日体温异常最多学校：</span>
        <span style="color: #00F9FF;font-weight: bold;margin-right:0.5rem">{{ unusualMostSchoolName }}</span>
      </p>
    </div>

    <div style="padding:0 0.26rem 0.2rem;margin-top: 0.2rem">
      <el-row>
        <el-col :span="12">
          <div style="width: 1.8rem;margin-bottom: 0.1rem" v-for="(item, index) in monitoringRate" :key="index">
            <p style="color: #00A2FF;font-weight: 400">{{ item.label }}</p>
            <div style="display: flex;flex-direction: row;align-items: center;margin-top: 0.1rem;">
              <div style="position:relative;height: 0.2rem;flex: 1">
                <div
                  style="width:100%;height: 2px;background: #263659;position:absolute;top: 50%;margin-top: -1px;z-index: 1"></div>
                <div
                  style="height: 4px;position:absolute;top: 50%;margin-top: -1px;z-index: 1;width: 0.9rem;left: 0"
                  :style="{'background': item.dot, 'width': item.value + '%'}"></div>
                <div
                  style="height: 0.1rem;width:0.1rem;border-radius:50%;box-shadow:0 0 0.1rem 4px #FFF992;z-index: 9;position:absolute;top: 50%;margin-top: -0.05rem"
                  :style="{'background': item.dot, 'left': item.value + '%'}"></div>
              </div>
              <div style="margin-left: 10px">
                <span style="color: #00F9FF;font-size: 0.14rem;float: right">{{item.value}}%</span>

              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="width: 100%;height:0.9rem">
            <pie :data="distribute" title="近一周体温异常分布"></pie>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    pie: () => import('./pie')
  },
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {

      statistics: [
        { label: '今日体温监测人次：', count: 0, code: 'totalTakeNum' },
        { label: '今日体温异常人数：', count: 0, code: 'totalUnusualNum' }
      ],
      unusualMostSchoolName: 0,
      monitoringRate: [
        {
          label: '学生监测率',
          code: 'studentProportion',
          value: 0,
          dot: '#9CFF95',
          background: 'linear-gradient(90deg, #2A435F 0%, #58E3A4 100%);'
        },
        {
          label: '教职工监测率',
          code: 'teacherProportion',
          value: 0,
          dot: '#FBCB65',
          background: 'linear-gradient(90deg, #625E56 0%, #FBCB65 100%);'
        }
      ],
      distribute: []
    }
  },

  watch: {
    data (newData) {
      this.init(newData)
    }
  },

  mounted () {
    this.init(this.data)
  },

  methods: {
    init (data) {
      this.statistics.forEach(item => {
        if (typeof data[item.code] !== 'undefined') {
          item.count = data[item.code]
        }
      })
      this.monitoringRate.forEach(item => {
        if (typeof data[item.code] !== 'undefined') {
          // item.value = ((parseFloat(data[item.code]) * 100)).toFixed(2)
          item.value = parseFloat(data[item.code])
        }
      })
      this.unusualMostSchoolName = data.unusualMostSchoolName || '-'
      const distribute = []
      const dataDistribute = data.districtLastWeekAbnormalTemperature || []
      dataDistribute.forEach(item => {
        distribute.push({
          name: item.areaName,
          value: item.unusualNum
        })
      })
      this.distribute = distribute
    }
  }
}
</script>
